@use 'styles/vapor' as vapor;

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

a, span {
  &.ActivityUpdatePreview {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: vapor.border-radius('extreme-large');
    background-color: vapor.color('white');
    padding: vapor.spacing('base');
    box-sizing: border-box;

    &:not(.shimmer) {
      box-shadow: vapor.shadow('drop-shadow-2');
    }

    &:not(.loading) {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: vapor.color('snow', 'base-100');
          transition: background-color 0.3s ease;
        }
      }
  
      &:focus-visible {
        background-color: vapor.color('snow', 'dark-100');
        transition: background-color 0.3s ease;
      }
      
      &:active {
        background-color: vapor.color('snow', 'dark-100');
      }
    }
    
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: vapor.spacing('base');
      width: 100%;
      height: 100%;

      &.contentLoading {
        visibility: hidden;
      }

      > :first-child {
        flex-shrink: 0;
      }
    }

    .description {
      width: 100%;
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    &.shimmer::after {
      content: "";
      position: absolute;
      inset: vapor.spacing('none');
      z-index: vapor.z-index('behind');
      background: conic-gradient(
        from var(--gradient-angle),
        vapor.color('graphica', 'dark-100'),
        vapor.color('graphica', 'lighter-100'),
        vapor.color('graphica', 'dark-100'),
        vapor.color('graphica', 'lighter-100'),
        vapor.color('graphica', 'dark-100'),
      );
      border-radius: inherit;
      animation: rotation 3s linear infinite;
      filter: blur(vapor.spacing('extra-tight'));
    }
  }
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}
