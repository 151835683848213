@use 'styles/vapor' as vapor;

@mixin notificationColors(
  $background-color,
  $border-color,
  $title-color,
  $content-color,
  $button-color
) {
  background: $background-color;
  border-color: $border-color;
  
  .heading {
    color: $title-color;
  }
  .content {
    color: $content-color;
  }
  .button {
    color: $button-color;
  }
}

.Notification {
  display: flex;
  flex-direction: row;
  padding: vapor.spacing('base-tight') vapor.spacing('base') vapor.spacing('base');
  border-radius: vapor.border-radius('extra-small');
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
}

.colorNeutral {
  @include notificationColors(
    vapor.color('steel', 'base-3'),
    transparent,
    vapor.color('ink',),
    vapor.color('ink', 'base-75'),
    vapor.color('ink',),
  );
}

.colorApproval {
  @include notificationColors(
    vapor.color('green', 'lighter-100'),
    vapor.color('green', 'dark-100'),
    vapor.color('green', 'dark-100'),
    vapor.color('green', 'dark-100'),
    vapor.color('green', 'dark-100'),
  );
}

.colorWarning {
  @include notificationColors(
    vapor.color('red', 'lighter-100'),
    vapor.color('red', 'dark-100'),
    vapor.color('red', 'dark-100'),
    vapor.color('red', 'dark-100'),
    vapor.color('red', 'dark-100'),
  );
}

.colorHighlight {
  @include notificationColors(
    transparent,
    vapor.color('black'),
    vapor.color('ink',),
    vapor.color('ink', 'base-75'),
    vapor.color('ink',),
  );
}

.body {
  text-align: left;
}

.heading {
  @include vapor.typography('body', 'base-bold');
  padding-bottom: vapor.spacing('extreme-tight');
}

.content {
  @include vapor.typography('body', 'small');
}

.iconWrap {
  margin-right: vapor.spacing('tight');
}

.spinnerWrap {
  margin-right: vapor.spacing('base-tight');
}

.icon svg {
  @include vapor.icon-size('medium');
}

button.button, a.button {
  @include vapor.typography('button', 'base');
  text-transform: uppercase;
  text-decoration: underline;
  padding: vapor.spacing('tight') 0 0;
}
