@use 'styles/vapor' as vapor;

$classic-border-width: 1px;
$relaxed-border-width: 1.5px;
$icon-outline-border-width: 1px;
$icon-outline-size: 40px;

$text-button-min-height: (
  'base': (
    min-height: 48px,
  ),
  'small': (
    min-height: 32px,
  ),
);

$outline-button-padding: (
  'classic': (
    padding: 3px 15px,
  ),
  'relaxed': (
    padding: 2.5px 14.5px,
  ),
);

button, a, span {
  &.Button, &.LinkButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: vapor.color('steel', 'base-100');
    box-sizing: border-box;
    // extra-tight padding on top and bottom, is minimum for text wrapping edge cases
    padding: vapor.spacing('extra-tight') vapor.spacing('base');

    &.fullWidth {
      width: 100%;
      max-width: 100%;
    }

    &.designClassic {
      @include vapor.typography('button', 'base');
      text-transform: uppercase;
    }

    &.designRelaxed {
      @include vapor.typography('button', 'large');
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover { 
        background-color: vapor.color('steel', 'base-5');
        transition: background-color 0.3s ease;
      }
    }

    &:focus-visible {
      background-color: vapor.color('steel', 'base-10');
      transition: background-color 0.3s ease;
    }
    
    &:active {
      background-color: vapor.color('steel', 'base-10');
    }

    &:disabled, &.disabled {
      background-color: vapor.color('steel', 'base-3');
      color: vapor.color('steel', 'base-30');
      border-color: transparent;
      cursor: default;
      pointer-events: none;
    }
  }

  &.primaryBlack {
    color: vapor.color('cloud');
    background-color: vapor.color('steel', 'base-100');
    
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: vapor.color('steel', 'base-90');
        transition: background-color 0.3s ease;
      }
    }

    &:focus-visible {
      background-color: vapor.color('steel', 'base-75');
      transition: background-color 0.3s ease;
    }
    
    &:active {
      background-color: vapor.color('steel', 'base-75');
    }
  }

  &.primaryJade {
    color: vapor.color('cloud');
    background-color: vapor.color('jade', 'base-100');
    
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: vapor.color('jade', 'base-90');
        transition: background-color 0.3s ease;
      }
    }

    &:focus-visible {
      background-color: vapor.color('jade', 'base-75');
      transition: background-color 0.3s ease;
    }
    
    &:active {
      background-color: vapor.color('jade', 'base-75');
    }
  }

  &.primaryRed {
    color: vapor.color('cloud');
    background-color: vapor.color('red', 'dark-100');
    
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: vapor.color('red', 'dark-90');
        transition: background-color 0.3s ease;
      }
    }

    &:focus-visible {
      background-color: vapor.color('red', 'dark-75');
      transition: background-color 0.3s ease;
    }
    
    &:active {
      background-color: vapor.color('red', 'dark-75');
    }
  }

  &.primaryBlue {
    color: vapor.color('cloud');
    background-color: vapor.color('blue', 'base-100');
    
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: vapor.color('blue', 'base-90');
        transition: background-color 0.3s ease;
      }
    }

    &:focus-visible {
      background-color: vapor.color('blue', 'base-75');
      transition: background-color 0.3s ease;
    }
    
    &:active {
      background-color: vapor.color('blue', 'base-75');
    }
  }

  &.outline {
    border: $classic-border-width solid vapor.color('steel');
    @include vapor.get-state($outline-button-padding, 'classic');

    &.designRelaxed {
      border-width: $relaxed-border-width;
      @include vapor.get-state($outline-button-padding, 'relaxed');
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: transparent;
        border-color: vapor.color('steel', 'base-50');
        transition: border-color 0.3s ease;
      }
    }

    &:focus-visible {
      background-color: transparent;
      border-color: vapor.color('steel', 'base-15');
      transition: border-color 0.3s ease;
    }
    
    &:active {
      background-color: transparent;
      border-color: vapor.color('steel', 'base-15');
    }

    &.iconOnly {
      box-sizing: border-box;
      padding: vapor.spacing('tight');
      border-width: $icon-outline-border-width;
      height: $icon-outline-size;
      width: $icon-outline-size;

      &.designClassic {
        border-radius: vapor.border-radius('extra-small');
      }
      
      &.designRelaxed {
        border-radius: vapor.border-radius('extra-large');
      }
    }
  }

  &.iconOnly {
    box-sizing: content-box;
    padding: vapor.spacing('extra-tight');

    &:not(.outline) {
      border-radius: vapor.border-radius('round');
    }
  }

  &.designClassic {
    border-radius: vapor.border-radius('extra-small');
  }

  &.textButtonSizeSmall {
    @include vapor.get-state($text-button-min-height, 'small');

    &.designRelaxed {
      border-radius: vapor.border-radius('base');
    }
  }

  &.textButtonSizeBase {
    @include vapor.get-state($text-button-min-height, 'base');

    &.designRelaxed {
      border-radius: vapor.border-radius('extra-large');
    }
  }

  .hideContent {
    opacity: 0;
  }

  // https://css-tricks.com/flexbox-and-absolute-positioning/
  .loadingSpinner {
    position: absolute;
  }
}
